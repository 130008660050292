import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClearIcon from '@material-ui/icons/Clear';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import XLSX from 'xlsx';
import BarLoader from 'react-spinners/BarLoader';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import Modal from 'commonComponents/modal/Modal';
import BeatLoader from 'react-spinners/BeatLoader';
import ReactTable from 'react-table';
import PaymentDetails from 'superAdminViews/finance/pay/PaymentDetails';
import Pagination from 'commonComponents/pagination/Pagination';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Datetime from 'react-datetime';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import style from 'shopAdminViews/orders/Orders.module.scss';
import TransactionDetails from '../transactions/TransactionDetails';

const GET_PAYMENTS = gql`
	query getPaymentsByAdmin(
		$limit: Int
		$skip: Int
		$_id: ID
		$user: ID
		$driver: ID
		$shop: ID
		$createdAtFrom: Date
		$createdAt: Date
		$from: Date
		$to: Date
		$status: PaymentStatus
		$transactionId: String
		$type: PaymentType
		$for: PaymentFor
		$amountFrom: Float
		$amount: Float
		$createdAtSort: Int
		$amountSort: Int
		$driverName: String
		$passengerName: String
		$shopName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
		$shopPhoneNumber: String
	) {
		getPaymentsByAdmin(
			pagination: { limit: $limit, skip: $skip }
			filters: {
				_id: $_id
				user: $user
				shop: $shop
				for: $for
				driver: $driver
				transactionId: $transactionId
				status: $status
				type: $type
				amountFrom: $amountFrom
				amount: $amount
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				from: $from
				to: $to
				driverName: $driverName
				passengerName: $passengerName
				shopName: $shopName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
				shopPhoneNumber: $shopPhoneNumber
			}
			sort: { createdAt: $createdAtSort, amount: $amountSort }
		) {
			_id
			for
			updatedAt
			user {
				_id
				fullName
				phoneNumber
			}
			driver {
				_id
				fullName
				phoneNumber
			}
			shop {
				_id
				name
				phoneNumbers
				shopAdmin {
					_id
					fullName
				}
			}
			transactionId {
				_id
				transactionId
				status
				paidAt
				type
				amount
				reversed
				payments {
					_id
					for
					status
					description
					createdAt
					amount
					type
				}
				user {
					_id
					fullName
					phoneNumber
				}
				driver {
					_id
					fullName
					phoneNumber
				}
				shop {
					_id
					name
					phoneNumbers
				}
				paymentIntent
				createdAt

				updatedAt
			}
			trip {
				_id
				driver {
					_id
					fullName
					phoneNumber
				}
				passengerDetail {
					_id
					fullName
					phoneNumber
				}
			}
			order {
				_id
				user {
					_id
					fullName
					phoneNumber
				}
				shop {
					_id
					name
					phoneNumbers
				}
			}
			status
			description
			createdAt
			amount
			type
		}
	}
`;

const GET_PAYMENTS_COUNT = gql`
	query getPaymentsByAdminCount(
		$_id: ID
		$user: ID
		$driver: ID
		$shop: ID
		$createdAtFrom: Date
		$createdAt: Date
		$from: Date
		$for: PaymentFor
		$transactionId: String
		$to: Date
		$status: PaymentStatus
		$type: PaymentType
		$amountFrom: Float
		$amount: Float
		$driverName: String
		$passengerName: String
		$shopName: String
		$driverPhoneNumber: String
		$passengerPhoneNumber: String
		$shopPhoneNumber: String
	) {
		getPaymentsByAdminCount(
			filters: {
				_id: $_id
				user: $user
				shop: $shop
				driver: $driver
				status: $status
				transactionId: $transactionId
				for: $for
				type: $type
				amountFrom: $amountFrom
				amount: $amount
				createdAtFrom: $createdAtFrom
				createdAt: $createdAt
				from: $from
				to: $to
				driverName: $driverName
				passengerName: $passengerName
				shopName: $shopName
				driverPhoneNumber: $driverPhoneNumber
				passengerPhoneNumber: $passengerPhoneNumber
				shopPhoneNumber: $shopPhoneNumber
			}
		)
	}
`;

const Payments = (props) => {
	const perPageOptions = ['5', '10', '20'];
	const [perPage, setPerPage] = useState(10);

	const [pageCount, setPageCount] = useState(0);

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: pageCount * perPage,
		_id: undefined,
		driverName: undefined,
		driverPhoneNumber: undefined,
		passengerName: undefined,
		passengerPhoneNumber: undefined,
		shopName: undefined,
		shopPhoneNumber: undefined,
		amountFrom: undefined,
		amount: undefined,
		from: undefined,
		to: undefined,
		type: undefined,
		for: undefined,
		createdAt: undefined,
		createdAtFrom: undefined,
		status: props.failed ? 'FAILED' : undefined,
		createdAtSort: -1,
		amountSort: undefined,
		transactionId: undefined,
	});

	const statusOptions = ['ALL', 'PAID', 'UNPAID', 'FAILED'];
	const typeOptions = ['ALL', 'PAY_TO_SPARK', 'PAY_TO_SHOP', 'PAY_TO_DRIVER'];
	const forOptions = ['ALL', 'DELIVERY', 'RIDE', 'RESTAURANT', 'PURCHASE'];

	const [idFilter, setIdFilter] = useState('');
	const [transactionIdFilter, setTransactionIdFilter] = useState('');
	const [amountFromFilter, setAmountFromFilter] = useState('');
	const [amountFilter, setAmountFilter] = useState('');
	const [statusFilter, setStatusFilter] = useState(props.failed ? 'FAILED' : statusOptions[0]);
	const [typeFilter, setTypeFilter] = useState(typeOptions[0]);
	const [forFilter, setForFilter] = useState(forOptions[0]);
	const [dateFromFilter, setDateFromFilter] = useState('');
	const [dateFilter, setDateFilter] = useState('');
	const [dateFromFilterError, setDateFromFilterError] = useState('');
	const [dateFilterError, setDateFilterError] = useState('');

	const [driverNameFilter, setDriverNameFilter] = useState('');
	const [passengerNameFilter, setPassengerNameFilter] = useState('');
	const [shopNameFilter, setShopNameFilter] = useState('');
	const [driverPhoneNumberFilter, setDriverPhoneNumberFilter] = useState('');
	const [passengerPhoneNumberFilter, setPassengerPhoneNumberFilter] = useState('');
	const [shopPhoneNumberFilter, setShopPhoneNumberFilter] = useState('');

	const [idFilterError, setIdFilterError] = useState(false);
	const [transactionIdFilterError, setTransactionIdFilterError] = useState(false);

	const [dateSort, setDateSort] = useState('down');
	const [amountSort, setAmountSort] = useState(null);
	const [noFilterModal, setNoFilterModal] = useState(false);

	const [paymentDetails, setPaymentDetails] = useState();
	const [modal, setModal] = useState(false);
	const [transactionDetailId, setTransactionDetailId] = useState();
	const [transactionModal, setTransactionModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectPaymentDetails = (payment) => {
		setPaymentDetails(payment);
		setModal(true);
	};

	const onSelectTransactionDetails = (transactionId) => {
		setTransactionDetailId(transactionId);
		setTransactionModal(true);
	};

	const [excelData, setExcelData] = useState([
		[
			'transaction ID',
			'driver name',
			'driver phone No',
			'user name',
			'user phone No',
			'shop name',
			'shop phone No',
			'amount',
			'date',
			'type',
			'for',
			'status',
		],
	]);

	const [getPaymentsByAdmin, { data: lazyData, loading: lazyLoading, error: lazyError }] = useLazyQuery(GET_PAYMENTS);

	const [showExcelButton, setShowExcelButton] = useState('firstStatus');

	useEffect(() => {
		if (
			countData &&
			countData.getPaymentsByAdminCount > 0 &&
			excelData.length === countData.getPaymentsByAdminCount + 1
		) {
			setShowExcelButton('done');
		}
	}, [excelData, countData, countLoading]);

	useEffect(() => {
		if (lazyData) {
			console.log(lazyData);

			setExcelData([
				...excelData,
				...Array.from(lazyData.getPaymentsByAdmin, (item) => [
					item.transactionId ? item.transactionId.transactionId : '-',
					item.driver ? item.driver.fullName : '-',
					item.driver ? item.driver.phoneNumber : '-',
					item.user ? item.user.fullName : '-',
					item.user ? item.user.phoneNumber : '-',
					item.shop ? item.shop.name : '-',
					item.shop && item.shop.phoneNumbers ? item.shop.phoneNumbers[0] : '-',
					item.amount ? item.amount.toFixed(2) : '-',
					moment(item.createdAt).format('MM-DD-YYYY'),
					item.type.replace(/_/g, ' ').toLowerCase(),
					item.for.replace(/_/g, ' ').toLowerCase(),
					item.status.toLowerCase(),
				]),
			]);
		}
	}, [, lazyData, lazyLoading]);

	const onExcelFinished = () => {
		let wb = XLSX.utils.book_new();
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(wb, ws, 'Trips');
		XLSX.writeFile(wb, 'Payments.xlsx');
	};

	const onDownloadData = () => {
		if (countData) {
			setShowExcelButton('downloading');
			getPaymentsByAdmin({ variables: { ...variables, skip: 0, limit: countData.getPaymentsByAdminCount } });
		}
	};

	const onDateSortChange = () => {
		if (!dateSort) {
			setDateSort('up');
		} else {
			if (dateSort === 'up') {
				setDateSort('down');
			} else {
				setDateSort('');
			}
		}
	};
	const onAmountSortChange = () => {
		if (!amountSort) {
			setAmountSort('up');
		} else {
			if (amountSort === 'up') {
				setAmountSort('down');
			} else {
				setAmountSort('');
			}
		}
	};

	useEffect(() => {
		if (dateSort) {
			if (dateSort === 'up') {
				setVariables((variables) => ({ ...variables, createdAtSort: 1 }));
			} else if (dateSort === 'down') {
				setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
			}
		} else {
			setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
		}
	}, [dateSort]);

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getPaymentsByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getPaymentsByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	useEffect(() => {
		setVariables((variables) => ({ ...variables, limit: perPage }));
	}, [perPage]);

	useEffect(() => {
		if (!dateFromFilter) {
			setDateFromFilterError('');
		} else {
			if (
				moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
				moment(dateFromFilter, 'MM-DD-YYYY', true).isValid()
			) {
				setDateFromFilterError('');
			} else {
				setDateFromFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (!dateFilter) {
			setDateFilterError('');
		} else {
			if (moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid()) {
				setDateFilterError('');
			} else {
				setDateFilterError('invalid date');
			}
		}
	}, [dateFilter]);

	useEffect(() => {
		if (idFilter && idFilter.length !== 24) {
			setIdFilterError(true);
		} else {
			setIdFilterError(false);
		}
	}, [idFilter]);

	useEffect(() => {
		if (transactionIdFilter && transactionIdFilter.length !== 27) {
			setTransactionIdFilterError(true);
		} else {
			setTransactionIdFilterError(false);
		}
	}, [transactionIdFilter]);

	const onSubmitFilter = () => {
		let hasFilter =
			idFilter ||
			variables._id ||
			transactionIdFilter ||
			variables.transactionId ||
			driverNameFilter ||
			variables.driverName ||
			driverPhoneNumberFilter ||
			variables.driverPhoneNumber ||
			shopNameFilter ||
			variables.shopNameFilter ||
			variables.shopName ||
			passengerNameFilter ||
			variables.passengerName ||
			passengerPhoneNumberFilter ||
			variables.passengerPhoneNumber ||
			dateFilter ||
			variables.createdAt ||
			dateFromFilter ||
			variables.createdAtFrom ||
			amountFromFilter ||
			variables.amountFrom ||
			amountFilter ||
			variables.amount ||
			!(typeFilter === 'ALL' && !variables.type) ||
			!(forFilter === 'ALL' && !variables.for) ||
			!(statusFilter === 'ALL' && !variables.status);

		if (!hasFilter) {
			setNoFilterModal(true);
		} else {
			setPageCount(0);
			setExcelData((excelData) => [excelData[0]]);
			setShowExcelButton('firstStatus');
			if (idFilter && idFilter.length === 24) {
				setVariables((variables) => ({
					...variables,
					_id: idFilter.trim(),
				}));
			} else if (!idFilter) {
				setVariables((variables) => ({ ...variables, _id: undefined }));
			}
			if (transactionIdFilter && transactionIdFilter.length === 27) {
				setVariables((variables) => ({
					...variables,
					transactionId: transactionIdFilter.trim(),
				}));
			} else if (!transactionIdFilter) {
				setVariables((variables) => ({ ...variables, transactionId: undefined }));
			}

			if (driverNameFilter) {
				setVariables((variables) => ({
					...variables,
					driverName: driverNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverName: undefined }));
			}

			if (driverPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					driverPhoneNumber: driverPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, driverPhoneNumber: undefined }));
			}

			if (passengerNameFilter) {
				setVariables((variables) => ({
					...variables,
					passengerName: passengerNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerName: undefined }));
			}

			if (passengerPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					passengerPhoneNumber: passengerPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, passengerPhoneNumber: undefined }));
			}

			if (shopNameFilter) {
				setVariables((variables) => ({
					...variables,
					shopName: shopNameFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopName: undefined }));
			}

			if (shopPhoneNumberFilter) {
				setVariables((variables) => ({
					...variables,
					shopPhoneNumber: shopPhoneNumberFilter,
				}));
			} else {
				setVariables((variables) => ({ ...variables, shopPhoneNumber: undefined }));
			}

			if (amountFromFilter) {
				setVariables((variables) => ({
					...variables,
					amountFrom: parseFloat(amountFromFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, amountFrom: undefined }));
			}

			if (amountFilter) {
				setVariables((variables) => ({
					...variables,
					amount: parseFloat(amountFilter),
				}));
			} else {
				setVariables((variables) => ({ ...variables, amount: undefined }));
			}

			if (
				dateFromFilter &&
				(moment(dateFromFilter, 'MM/DD/YYYY', true).isValid() ||
					moment(dateFromFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAtFrom: moment(dateFromFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAtFrom: undefined }));
			}

			if (
				dateFilter &&
				(moment(dateFilter, 'MM/DD/YYYY', true).isValid() || moment(dateFilter, 'MM-DD-YYYY', true).isValid())
			) {
				setVariables((variables) => ({
					...variables,
					createdAt: moment(dateFilter).format('YYYY-MM-DD'),
				}));
			} else {
				setVariables((variables) => ({ ...variables, createdAt: undefined }));
			}

			if (typeFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					type: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					type: typeFilter.toUpperCase(),
				}));
			}

			if (forFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					for: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					for: forFilter.toUpperCase(),
				}));
			}

			if (statusFilter === 'ALL') {
				setVariables((variables) => ({
					...variables,
					status: undefined,
				}));
			} else {
				setVariables((variables) => ({
					...variables,
					status: statusFilter.toUpperCase(),
				}));
			}

			if (dateSort) {
				if (dateSort === 'up') {
					setVariables((variables) => ({ ...variables, createdAtSort: 0 }));
				} else if (dateSort === 'down') {
					setVariables((variables) => ({ ...variables, createdAtSort: -1 }));
				}
			} else {
				setVariables((variables) => ({ ...variables, createdAtSort: undefined }));
			}

			if (amountSort) {
				if (amountSort === 'up') {
					setVariables((variables) => ({ ...variables, amountSort: 0 }));
				} else if (amountSort === 'down') {
					setVariables((variables) => ({ ...variables, amountSort: -1 }));
				}
			} else {
				setVariables((variables) => ({ ...variables, amountSort: undefined }));
			}
		}
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = useState(null);

	const handleClick = (event, id) => {
		setMenuId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMenuId(null);
	};

	const onClearFilters = () => {
		setExcelData((excelData) => [excelData[0]]);
		setShowExcelButton('firstStatus');
		setVariables({
			limit: perPage,
			skip: pageCount * perPage,
			_id: undefined,
			driverName: undefined,
			driverPhoneNumber: undefined,
			passengerName: undefined,
			passengerPhoneNumber: undefined,
			shopName: undefined,
			shopPhoneNumber: undefined,
			amount: undefined,
			createdAt: undefined,
			createdAtFrom: undefined,
			amountFrom: undefined,
			from: undefined,
			to: undefined,
			type: undefined,
			for: undefined,
			status: props.failed ? 'FAILED' : undefined,
			createdAtSort: -1,
			amountSort: undefined,
			transactionId: undefined,
		});
		setIdFilter('');
		setDateFilter('');
		setDateFromFilter('');
		setForFilter('ALL');
		setTypeFilter('ALL');
		setAmountFromFilter('');
		setAmountFilter('');
		setStatusFilter('ALL');
		setShopNameFilter('');
		setDriverNameFilter('');
		setPassengerNameFilter('');
		setTransactionIdFilter('');
		setShopPhoneNumberFilter('');
		setPassengerPhoneNumberFilter('');
		setDriverPhoneNumberFilter('');
		setAmountSort('');
		setDateSort('down');
	};

	const handleEnter = (e) => {
		if (e.charCode === 13) {
			onSubmitFilter();
		}
	};

	const { loading, error, data, refetch } = useQuery(GET_PAYMENTS, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_PAYMENTS_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	// calculate total price
	const [total, setTotal] = useState(0);
	useEffect(() => {
		if (data) {
			let number = 0;
			data.getPaymentsByAdmin.map((item) => {
				number = number + item.amount;
			});
			setTotal(number);
		}
	}, [data, loading]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div onKeyPress={(e) => handleEnter(e)}>
			{showExcelButton === 'done' && (
				<Button color="whiteButton" onClick={() => onExcelFinished()}>
					<i class="fas fa-cloud-download-alt"></i> download excel
				</Button>
			)}

			{showExcelButton === 'firstStatus' && (
				<div>
					<Button color="whiteButton" onClick={() => onDownloadData()}>
						<i class="fas fa-file-export"></i> export excel
					</Button>
				</div>
			)}
			{showExcelButton === 'downloading' && (
				<div className={style.excelLoadingDiv}>
					<BarLoader
						css={css`
							display: block;
							margin: 0 auto;
							border-color: red;
						`}
						size={15}
						margin={2}
						color={'gray'}
						loading={true}
					/>
				</div>
			)}
			<div className={style.filterDiv}>
				<div>
					<Button color="yellow" onClick={onSubmitFilter}>
						<SortIcon />
						Search
					</Button>
					<Button title="reset all filters and sorts" color="redButton" size="sm" onClick={onClearFilters}>
						<ClearIcon />
						Default
					</Button>
				</div>
				{data && countData && (
					<div className={style.rowConfigDriv}>
						<div className={style.itemsNumberDiv}>
							Displaying items{' '}
							<span>{countData.getPaymentsByAdminCount === 0 ? 0 : pageCount * perPage + 1}</span> to{' '}
							<span>{pageCount * perPage + Math.min(perPage, data.getPaymentsByAdmin.length)}</span> of{' '}
							<span>{countData.getPaymentsByAdminCount}</span>
						</div>
						<div className={style.rowDropDownDiv}>
							<div className={style.rowLabel}>Row:</div>{' '}
							<CustomDropdown
								hoverColor="primary"
								buttonText={String(perPage)}
								onClick={(e) => {
									setPageCount(0)
									setPerPage(parseInt(e));
								}}
								dropdownList={perPageOptions}
							/>
						</div>
					</div>
				)}
			</div>

			<ReactTable
				data={[
					...data.getPaymentsByAdmin.map((payment) => ({
						id: (
							<CopyToClipboard text={payment._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment._id}
								</span>
							</CopyToClipboard>
						),
						transactionId: (
							<CopyToClipboard
								text={
									payment.transactionId &&
									payment.transactionId.transactionId &&
									payment.transactionId.transactionId
								}
							>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.transactionId &&
										payment.transactionId.transactionId &&
										payment.transactionId.transactionId}
								</span>
							</CopyToClipboard>
						),

						userName:
							payment.user && payment.user.fullName
								? payment.user.fullName
								: payment.order 
								? payment.order.user.fullName
								: payment.trip && payment.trip.passengerDetail
								? payment.trip.passengerDetail.fullName
								: '',
						driverName:
							payment.driver && payment.driver.fullName
								? payment.driver.fullName
								: payment.trip && payment.trip.driver
								? payment.trip.driver.fullName
								: '',
						shopName:
							payment.shop && payment.shop.name
								? payment.shop.name
								: payment.order
								? payment.order.shop.name
								: '',
						userPhoneNumber:
							payment.user && payment.user.phoneNumber
								? payment.user.phoneNumber
								: payment.order
								? payment.order.user.phoneNumber
								: payment.trip && payment.trip.passengerDetail
								? payment.trip.passengerDetail.phoneNumber
								: '',
						driverPhoneNumber:
							payment.driver && payment.driver.phoneNumber
								? payment.driver.phoneNumber
								: payment.trip && payment.trip.driver
								? payment.trip.driver.phoneNumber
								: '',
						shopPhoneNumber:
							payment.shop && payment.shop.phoneNumbers
								? payment.shop.phoneNumbers[0]
								: payment.order
								? payment.order.shop.phoneNumbers[0]
								: '',
						driver: payment.driver && (
							<CopyToClipboard text={payment.driver._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.driver._id}
								</span>
							</CopyToClipboard>
						),
						shop: payment.shop && (
							<CopyToClipboard text={payment.shop._id}>
								<span title="Click To Copy To Clipboard" className={style.copyClipboard}>
									{payment.shop._id}
								</span>
							</CopyToClipboard>
						),
						type: payment.type && (
							<div
								className={`${style.inlineTag} ${
									style[payment.type.replace(/_/g, '').toLowerCase() + 'Tag']
								}`}
							>
								{payment.type.replace(/_/g, ' ').toLowerCase()}
							</div>
						),
						for: payment.for && (
							<div className={`${style.inlineTag} ${style[payment.for.toLowerCase() + 'Tag']}`}>
								{payment.for.toLowerCase()}
							</div>
						),
						status: payment.status && (
							<div className={`${style.inlineTag} ${style[payment.status.toLowerCase() + 'Tag']}`}>
								{payment.status.toLowerCase()}
							</div>
						),
						amount: <div>{payment.amount && '$' + payment.amount.toFixed(2)} </div>,
						date: moment(payment.createdAt).format('MM/DD/YYYY HH:mm'),
						actions: (
							<div>
								<IconButton
									id={payment._id}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleClick(e, payment._id);
									}}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={menuId === payment._id}
									onClose={handleClose}
								>
									<MenuItem onClick={() => onSelectPaymentDetails(payment)}>payment details</MenuItem>
									{payment.transactionId && (
										<MenuItem onClick={() => onSelectTransactionDetails(payment.transactionId)}>
											transaction details
										</MenuItem>
									)}
								</Menu>
							</div>
						),
					})),
					data.getPaymentsByAdmin.length > 0 && {
						transactionId: <div className={style.totalNumber}>Total:</div>,
						amount: <div className={style.totalNumber}> {'$' + total.toFixed(2)}</div>,
					},
				]}
				columns={[
					// {
					// 	Header: (
					// 		<div>
					// 			<div className={style.titleDivSmall}>ID</div>
					// 			{idFilterError && <div className={style.dateErrorDiv}>must be 24 character</div>}
					// 			<CustomInput
					// 				value={idFilter}
					// 				onChange={(e) => setIdFilter(e.target.value)}
					// 				id="regular"
					// 				error={idFilterError && idFilter}
					// 				inputProps={{
					// 					placeholder: 'id ...',
					// 				}}
					// 				formControlProps={{
					// 					fullWidth: true,
					// 				}}
					// 			/>
					// 		</div>
					// 	),
					// 	accessor: 'id',
					// 	sortable: false,
					// 	filterable: false,
					// 	width: 220,
					// },
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Transaction ID</div>
								{transactionIdFilterError && (
									<div className={style.dateErrorDiv}>must be 27 character</div>
								)}
								<CustomInput
									value={transactionIdFilter}
									onChange={(e) => setTransactionIdFilter(e.target.value)}
									id="regular"
									error={transactionIdFilterError && transactionIdFilter}
									inputProps={{
										placeholder: 'ID ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'transactionId',
						sortable: false,
						filterable: false,
						width: 220,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Driver Name</div>
								<CustomInput
									value={driverNameFilter}
									onChange={(e) => setDriverNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>Driver Phone No</div>
								<CustomInput
									value={driverPhoneNumberFilter}
									onChange={(e) => setDriverPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'driverPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>User Name</div>
								<CustomInput
									value={passengerNameFilter}
									onChange={(e) => setPassengerNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'userName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDivSmall}>User Phone No</div>
								<CustomInput
									value={passengerPhoneNumberFilter}
									onChange={(e) => setPassengerPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'userPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Shop Name</div>
								<CustomInput
									value={shopNameFilter}
									onChange={(e) => setShopNameFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'name ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopName',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Shop Phone No</div>
								<CustomInput
									value={shopPhoneNumberFilter}
									onChange={(e) => setShopPhoneNumberFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'phone ...',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'shopPhoneNumber',
						sortable: false,
						filterable: false,
						minWidth: 140,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onAmountSortChange}>
									Amount{' '}
									<span>
										{amountSort && (
											<span>
												{amountSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								<CustomInput
									value={amountFromFilter}
									onChange={(e) => setAmountFromFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'from',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
								<CustomInput
									value={amountFilter}
									onChange={(e) => setAmountFilter(e.target.value)}
									id="regular"
									inputProps={{
										placeholder: 'to',
									}}
									formControlProps={{
										fullWidth: true,
									}}
								/>
							</div>
						),
						accessor: 'amount',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className="twoFilter">
								<div className={style.sortDiv} onClick={onDateSortChange}>
									Date{' '}
									<span>
										{dateSort && (
											<span>
												{dateSort === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
											</span>
										)}
									</span>
								</div>
								{dateFromFilterError && <div className={style.dateErrorDiv}>{dateFromFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFromFilter}
										viewDate={moment(dateFromFilter).format('MM/DD/YYYY')}
										closeOnSelect
										onChange={(date) => setDateFromFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'from' }}
									/>
								</FormControl>
								{dateFilterError && <div className={style.dateErrorDiv}>{dateFilterError}</div>}
								<FormControl fullWidth>
									<Datetime
										disableOnClickOutside
										value={dateFilter}
										viewDate={moment(dateFilter).format('MM/DD/YYYY')}
										closeOnSelect
										onChange={(date) => setDateFilter(date)}
										timeFormat={false}
										inputProps={{ placeholder: 'to' }}
									/>
								</FormControl>
							</div>
						),
						accessor: 'date',
						sortable: false,
						filterable: false,
						width: 140,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Type</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={typeFilter}
										onClick={(e) => setTypeFilter(e)}
										dropdownList={typeOptions}
									/>
								</div>
							</div>
						),
						accessor: 'type',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>For</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={forFilter}
										onClick={(e) => setForFilter(e)}
										dropdownList={forOptions}
									/>
								</div>
							</div>
						),
						accessor: 'for',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div>
								<div className={style.titleDiv}>Status</div>
								<div className={style.dropdownWrapper}>
									<CustomDropdown
										hoverColor="primary"
										buttonText={statusFilter}
										onClick={(e) => setStatusFilter(e)}
										dropdownList={statusOptions}
									/>
								</div>
							</div>
						),
						accessor: 'status',
						sortable: false,
						filterable: false,
					},
					{
						Header: (
							<div className={style.bottonButton}>
								<div className={style.titleDivAction}>Actions</div>
							</div>
						),
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage + 1}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={
						countData.getPaymentsByAdminCount === 0
							? 1
							: Math.ceil(countData.getPaymentsByAdminCount / perPage)
					}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<PaymentDetails
						close={() => setModal(false)}
						refetch={() => refetch()}
						payment={paymentDetails}
						hasResolver={true}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={transactionModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<TransactionDetails
						close={() => setTransactionModal(false)}
						transaction={transactionDetailId}
						hasPaymentsDetails={false}
					/>
				</DialogContent>
			</Dialog>
			<Dialog open={noFilterModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => {
							setNoFilterModal(false);
						}}
						type="notif"
						text={
							"In order to do the search, type at least one of the parameters in the following table's columns."
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default Payments;
